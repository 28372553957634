<template>
    <div class="space-y-6">
        <h1 class="title">{{ title }}</h1>
        <div class="space-y-6 card p-8 rounded-3xl shadow-md bg-blue-50">
            <div class="flex justify-center">
                <img class="card-content object-cover h-52 w-52 rounded-full" src="../assets/me.jpg" alt="My Image" />
            </div>
            <div class="text-center text-lg font-medium text-blue-900">
                <p>Frontend Developer | Vue.js</p>
            </div>
            <div class="card-content space-y-4 py-4 rounded-3xl">
                <div class="font-medium">
                    <ul>
                        <li class="info">
                            <p>Iqmal Bin Shamsul Bahari</p>
                        </li>
                        <li class="info">
                            <PhoneIcon class="icon" /><p>+60 12 548 5143</p>
                        </li>
                        <li class="info">
                            <EmailIcon class="icon" /><p>contact@iqmalsb.com</p>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-around px-4">
                    <a href="https://github.com/iqmalsb"><GitHubIcon class="socials" /></a>
                    <a href="https://www.linkedin.com/in/iqmalsb/"><LinkedInIcon class="socials" /></a>
                    <a href="https://www.instagram.com/iqmalsb/"><InstagramIcon class="socials" /></a>
                    <a href="https://api.whatsapp.com/send?phone=0125485143"><WhatsAppIcon class="socials" /></a>
                </div>
            </div>
            <div>
                <p class="text-xs text-justify">24 y/o computer science graduate based in Shah Alam, Malaysia. <br><br>Looking for any available opportunities in working together. <br><br>
                Enjoys playing golf during spare time.</p>
            </div>
        </div>
    </div>
</template>

<script>
import EmailIcon from './icons/EmailIcon.vue'
import PhoneIcon from './icons/PhoneIcon.vue'
import GitHubIcon from './icons/GitHubIcon.vue'
import LinkedInIcon from './icons/LinkedInIcon.vue'
import InstagramIcon from './icons/InstagramIcon.vue'
import WhatsAppIcon from './icons/WhatsAppIcon.vue'

export default {
    props: [ 'title' ],
    components: { GitHubIcon, LinkedInIcon, InstagramIcon, WhatsAppIcon, EmailIcon, PhoneIcon }
}
</script>

<style scoped>
    .card {
        @apply border-2 border-blue-900
    } 
    .card-content {
        @apply border-2 border-blue-900 bg-white
    }
    .socials {
        @apply h-8 w-8 text-blue-900
    }
    .icon {
        @apply h-4 w-4 text-blue-900
    }
    .info {
        @apply flex items-center justify-center space-x-2 text-base text-center 
    }
</style>
