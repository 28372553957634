<template>
    <!-- Logo Homepage -->
    <!-- NavBar Icon -->
    <!-- NavBar Dropdown List -->
    <div class="items-center px-6 py-2 bg-blue-50">
        <div class="flex justify-between">
            <span class="font-logo text-2xl font-medium text-blue-900">IQMALSB</span>
            <div class="w-6 self-center" @click="displayMenu()">
                <MenuIcon />
            </div>
        </div>
    </div>
    <div class="items-center text-center">
        <ul :class="{ hidden: !displayNav }" 
        class="nav-list">
            <li class="bg-blue-50" v-for="item in section" :key="item">{{ item.name }}</li>
        </ul>
    </div>
</template>

<script>
import MenuIcon from './icons/MenuIcon.vue'

export default {
    components: { MenuIcon },
    props: ['section'],
    data() {
        return {
            displayNav : false,
        }
    },

    methods: {
        displayMenu() {
            return this.displayNav = !this.displayNav
        }
    },
}
</script>
