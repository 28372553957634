<template>
<!--
  * Navbar ✔
  * About Me Section ✔
  * Work Experience
  * Education
  * Skills & Expertise
  * Past Work & Projects
  * Contact Info
 -->
  <div class="mx-auto w-screen">
  <Navigation :section="items" />
  <AboutMe id="about-me" :title="items[0].name"/>
  <WorkExperience id="work-experience" :title="items[1].name" />
  <Education id="education" :title="items[2].name" />
  <SkillsAndExpertise id="skills-and-expertise" :title="items[3].name" />
  <WorkAndProjects id="work-and-projects" :title="items[4].name" />
  <ContactInfo id="contact-info" :title="items[5].name" />
 </div>

</template>

<script>
import Navigation from './components/Navigation.vue'
import AboutMe from './components/AboutMe.vue'
import WorkExperience from './components/WorkExperience.vue'
import Education from './components/Education.vue'
import SkillsAndExpertise from './components/SkillsAndExpertise.vue'
import WorkAndProjects from './components/WorkAndProjects.vue'
import ContactInfo from './components/ContactInfo.vue'

export default {
  name: 'App',
  mounted() {  
    document.title = "Iqmal Shamsul Bahari";  
  },
  components: {
    Navigation, AboutMe, WorkExperience, Education, SkillsAndExpertise, WorkAndProjects, ContactInfo
  },

  data() {
    return {
      items: [ 
                    {name: 'About Me'}, 
                    {name: 'Work Experience'}, 
                    {name: 'Education'}, 
                    {name: 'Skills & Expertise'}, 
                    {name: 'Past Projects'}, 
                    {name: 'Contact Info'}
      ],
    }
  }
}
</script>

<style scoped>

  #about-me, 
  #work-experience, 
  #education, 
  #skills-and-expertise, 
  #work-and-projects, 
  #contact-info {
    @apply py-6 px-6
  }

  #about-me, 
  #education, 
  #work-and-projects {
    @apply bg-white
  }

  #work-experience, 
  #skills-and-expertise, 
  #contact-info {
    @apply bg-blue-50
  }
  
</style>