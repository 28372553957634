<template>
    <h1 class="text-2xl uppercase text-center text-blue-900">{{ title }}</h1>
</template>

<script>

export default {
    props: [ 'title' ]
}
</script>
