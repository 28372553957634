import { createApp } from 'vue'
import App from './App.vue'
import './index.css'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCk7RVsUHJf-EeoDZqQzO4WYHfLjBYBBfc",
  authDomain: "my-website-ace3c.firebaseapp.com",
  projectId: "my-website-ace3c",
  storageBucket: "my-website-ace3c.appspot.com",
  messagingSenderId: "603015688277",
  appId: "1:603015688277:web:537c8e037f811296aee4dc",
  measurementId: "G-TLVWBC4J1W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notifivation_received');
// const db = getFirestore(app);


createApp(App).mount('#app')
